:root {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* IE11+ */
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  text-size-adjust: 100%; /* iOS 8+ */
}

audio:not([controls]) {
  display: none; /* Chrome 40+, iOS 8+, Safari 8+ */
}

details {
  display: block; /* Firefox 36+, Internet Explorer 11+, Windows Phone 8.1+ */
}

input,
textarea,
select {
  outline: none;
}

input[type='number'] {
  width: auto; /* Firefox 36+ */
}

input[type='search'] {
  -webkit-appearance: textfield; /* Safari 8+ */
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; /* Safari 8+ */
}

main {
  display: block; /* Android 4.3-, Internet Explorer 11+, Windows Phone 8.1+ */
}

summary {
  display: block; /* Firefox 36+, Internet Explorer 11+, Windows Phone 8.1+ */
}

pre {
  overflow: auto; /* Internet Explorer 11+ */
}

progress {
  display: inline-block; /* Internet Explorer 11+, Windows Phone 8.1+ */
}

small {
  font-size: 75%; /* All browsers */
}

template {
  display: none; /* Android 4.3-, Internet Explorer 11+, iOS 7-, Safari 7-, Windows Phone 8.1+ */
}

textarea {
  overflow: auto; /* Internet Explorer 11+ */
}

[hidden] {
  display: none; /* Internet Explorer 10- */
}

[unselectable] {
  user-select: none; /* Android 4.4+, Chrome 40+, Firefox 36+, iOS 8+, Safari 8+ */
}

/*
 * Universal inheritance
 */

*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

/*
 * Opinionated defaults
 */

/* specify solid border style of all elements */

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

/* remove margin and padding of all elements */

* {
  margin: 0;
  padding: 0;
}

/* specify the root styles of the document */

:root {
  box-sizing: border-box;
  cursor: default;
  font: 16px / 1.5 sans-serif;
  text-rendering: optimizeLegibility;
}

/* specify the html background color */

html {
  background-color: #ffffff;
}

/* specify the text decoration of anchors */

a {
  text-decoration: none;
}

/* specify media element style */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/* specify the background color of form elements */

button,
input,
select,
textarea {
  background-color: transparent;
}

/* specify inheritance of form elements */

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

/* specify the minimum height of form elements */

button,
[type='button'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='reset'],
[type='search'],
[type='submit'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
select,
textarea {
  min-height: 1.5;
}

/* specify the font family of code elements */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

/* specify the list style of nav lists */

nav ol,
nav ul {
  list-style: none;
}

/* style select like a standard input */

select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
}

select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
}

/* specify the border styles of tables */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* specify textarea resizability */

textarea {
  resize: vertical;
}

/* specify text selection background color and omit drop shadow */

::selection {
  background-color: #b3d4fc; /* required when declaring ::selection */
  text-shadow: none;
}

/* hide content from screens but not screenreaders */

@media screen {
  [hidden~='screen'] {
    display: inherit;
  }

  [hidden~='screen']:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}
